@import "../abstracts/variables";

@property --myColor1 {
    syntax: '<color>';
    initial-value: #2B2B37;
    inherits: false;
}
  
@property --myColor2 {
    syntax: '<color>';
    initial-value: #2B2B37;
    inherits: false;
}

.footer {
    display: flex;
    flex-direction: row;
    color: white;
    margin: 0;
    padding: 0;
    padding-bottom: 10vh;
}


.link_box {
    max-width: 238px;
    padding-left: 2.5rem;
    z-index: 2;
    color: white;
    text-decoration: none;
    cursor: pointer;  
    h2 {
        font-size: 2rem;
        line-height: 3rem;
        margin: 0;
    }

    p {
        font-size: 1rem;
        margin: 0;
    }
}

.box_content {
    padding: 1.5rem 2rem;
    overflow: hidden;
    background-color: rgb(0 0 0 / 10%);
    backdrop-filter: blur(8px) saturate(60%);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-radius: 5px;
    transition: --myColor1 0.4s, --myColor1 0.4s;
}

@media (max-width: 768px) {
    .footer {
        width: 100%;
        flex-direction: column;
        align-items: center;
        align-content: center;
        margin-left: 0;
        padding-bottom: 0;
        a {
            width: 100%;
            padding-left: 0;
        }
        .box_content {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            margin-bottom: 50px;
        p {
                display: none;
            }
        }
    }
}