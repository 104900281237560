
.home_about{
  height: 70vh;
  height: 70lvh;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  overflow-x: hidden;
  align-items: center;
}

.about_text{
  width: 60%;
  margin-left: 3vh;
  margin-right: 3vh;
  p {
      font-family: 'Montserrat', sans-serif;
      position: relative;
      margin: 1.5rem 0;
      font-size: 1.4rem;
      line-height: 2.8vh;
  }
  h2 {
      line-height: 9vh;
      font-family: 'Lobster', cursive;
      color: #08fdd8;
      font-weight: 400;
      margin-top: 0;
      position: relative;
      margin-bottom: 40px;
      left: -10px;
      font-size: 5rem;
  }
}

.about_picture {
  max-width: 300px;
  aspect-ratio: 1 / 1.5;
  display: grid;
  align-items: end;
  justify-content: center;
  border-radius: 0 0 100vw 100vw;
  overflow: hidden;

  transition: transform 250ms ease-in-out;

  img {
    position: relative;
    z-index: 2;
    transform: scale(0.85);
  }

  .circle {
    width: 300px;
    height: 300px;
    background-color: rgb(0 0 0 / 10%);
    backdrop-filter: blur(8px) saturate(60%);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: absolute;
    margin-inline: auto;
    transition: transform 250ms ease-in-out;
    z-index: 2;

  }
  .circle::before{
    background-color: #EE1D52;
    content: "";
    filter: blur(60px);
    opacity: 0.16;
    position: absolute;
    z-index: 0;
    animation: color infinite 8s ease-in-out;
  }
  .person_img {
    transition: transform 250ms ease-in-out;
  }
}

.about_picture:hover {
  transform: scale(1.05);
  .person_img {
    transform: 
      translateY(-2rem);
  }
}


.about_text p:first-of-type:before {
  content: '<p>';
  font-family: 'La Belle Aurore',cursive;
  color: #515152;
  font-size: 18px;
  position: absolute;
  margin-top: -1.5rem;
  left: -2rem;
  }

.about_text p:last-of-type:after {
  content: '<p/>';
  font-family: 'La Belle Aurore',cursive;
  color: #515152;
  font-size: 18px;
  position: absolute;
  left: -2rem;
  bottom: -2rem;
}

/* Phone settings */

@media (max-width: 768px) {
  .home_about {
    height: 100vh;
    height: 100lvh;
    margin-left: 0px;
    flex-direction: column;
    overflow-x: hidden;
  }
  .about_text {
    width: 80%;
    margin-left: 0;
    margin-right: 0;
    h2 {
      font-size: 4rem;
    }
    p {
      font-size: 1rem;
    }
  }
  .about_lottie {
    height: stretch;
    margin-bottom: 20px;
  }
}