/* General body and style */
@import "../abstracts/variables";

@font-face {
  font-family: 'Wotfard';
  src: url('../fonts/wotfard-regular/Wotfard_Regular/Wotfard_Regular_Webfont/Wotfard-Regular-webfont/wotfard-regular-webfont.ttf')
  format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}

*{
  -ms-overflow-style: none;
}

// scrollbar style
::-webkit-scrollbar {
  display: none;
}

body {
  // background-color: $background;
  color: $white;
  font-size: x-large;
  margin: 0;
  font-family: 'Wotfard';
  overflow-x: hidden;
}

.loader {
  height: 100%;
  width: 100%;
  // background-color: #181818;
}

.content {
  width: 100%;
  overflow-x: hidden;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
}

/* NavigationBar */

.NavigationBarContainer {
  position: fixed;
  font-family: 'Inter';
  font-weight: bold;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: #1d1d1d;
  -webkit-box-shadow: 10px 10px 10px 10px rgb(0 0 0 / 10%), 0 10px 10px 0 rgb(0 0 0 / 10%);
  -moz-box-shadow:    10px 10px 10px 10px rgb(0 0 0 / 10%), 0 10px 10px 0 rgb(0 0 0 / 10%);
  box-shadow:         10px 10px 10px 10px rgb(0 0 0 / 10%), 0 10px 10px 0 rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 3;
  ul, ol {
    list-style: none;
  }
  button {
    padding-right: 30px;
    background-color: transparent;
    border: none;
    padding-left: 30px;
    height: 40px;
    border-right: 1px solid #4d4d4d;
    display: flex;
    align-items: center;
    font-family: 'Lato', sans-serif;
    transition: color 0.3s ease;
    text-decoration: none;
    font-weight: 600;
    letter-spacing: .2em;
    font-size: 15px;
  }
  button:first-of-type {
    border-left: 1px solid #4d4d4d;
  }
  button:hover {
    cursor: pointer;
    color:#ff1b54;
  }
  .active {
    position: relative;
    color: #ff1b54;
  }
  .active::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 15%;
    width: 70%;
    height: 1px;
    background-color: #ff1b54;
    opacity: 1;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
  }

  .inactive {
    position: relative;
    color: #787878;
  }

  .inactive::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 15%;
    width: 70%;
    height: 1px;
    background-color: #ff1b54;
    opacity: 1;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
  }
  
  @keyframes fade-out {
    from {
      opacity: 1;
      width: 70%;
    }
    to {
      opacity: 0;
      width: 0;
    }
  }

  @keyframes fade-in {
    from {
      opacity: 0;
      width: 0;
    }
    to {
      opacity: 1;
      width: 70%;
    }
  }
  
  .nav-link.active::before {
    animation-name: fade-in;
  }

  .nav-link.inactive::before {
    animation-name: fade-out;
  }

}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .NavigationBarContainer {
    background-color: #0000006e;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }
}

/* Selectie */

::selection{
  background-color: white;
  color:#1d1d1d;
  text-shadow: none;
}

/* PDF settings */

.PDF {
  font-family: 'Roboto', sans-serif;
  position: fixed;
  bottom: 10px;
  right: 10px;
  height: 80px;
  width: 180px;
  color: rgb(255, 255, 255);
  font-size: 1rem;
  border-radius: 3px;
  z-index: 2;
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  -webkit-box-shadow: 10px 10px 10px 10px rgb(0 0 0 / 10%), 0 5px 5px 0 rgb(0 0 0 / 10%);
  -moz-box-shadow: 10px 10px 10px 10px rgb(0 0 0 / 10%), 0 5px 5px 0 rgb(0 0 0 / 10%);
  box-shadow: 10px 10px 10px 10px rgb(0 0 0 / 10%), 0 5px 5px 0 rgb(0 0 0 / 10%);
  line-height: 0px;
  transition: color 0.3s ease, background-color 0.3s ease;
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .PDF {
    background-color: #00000070;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
}

.PDF:hover {
  color: #EE1D52;
  background-color: #000000;
  opacity: 1;
  cursor: pointer;
  font-weight: 400;
}

/* Tags */

.tags {
  margin-left: 150px;
  color: #515152;
  position: absolute;
  bottom: 0;
  left: 4rem;
  font-style: italic;
  font-size: 1.5rem;
  font-family: 'La Belle Aurore',cursive;
}

.top-tags {
  bottom: auto;
  top: 2rem;
}

.top-tags:after {
  content: '<body>';
  font-family: 'La Belle Aurore',cursive;
  color: #515152;
  font-size: 1.5rem;
  position: absolute;
  margin-top: 2rem;
  margin-left: -2rem;
}

.bottom-tags {
  bottom: 2rem;
  top: auto;
}

.bottom-tags:before {
  content: '</body>';
  font-family: 'La Belle Aurore',cursive;
  color: #515152;
  font-size: 1.5rem;
  position: absolute;
  margin-left: 2.9rem;
  margin-top: -2rem;
}

.background_objects {
  overflow-x: hidden;
  .TopText {
    position: absolute;
    top: 100px;
    right: 0;
    width: 200px;
    font-size: 0.7rem;
    padding-right: 30px;
    font-family: 'Inter';
    float: right;
    text-align: right;
    }
}

/* Phone settings */

@media (max-width: 768px) {
  body{
    width: 100% !important;
  }
  .content{
    overflow-x: hidden;
  }
  .row {
    flex-direction: column;
  }
  .home {
    flex-direction: column !important; 
  }
  .tags {
    display: none;
  }
  .menu-photo{
    display: none;
  }
  .menu-icons {
    display: none;
  }
  .PDF {
    display: none;
    height: 60px;
    width: 100px;
    font-size: 0.8rem;
    a {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
}