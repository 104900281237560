
.skills_container{
  height: 1140px;
  // height: 100lvh;
  display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
  background: transparent;
    svg {
        position: absolute;
        z-index: 0;
        opacity: 0.41;
    }
}

.skills_text{
    width: 40%;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    p {
      font-family: 'Montserrat', sans-serif;
      position: relative;
      margin: 1rem 0;
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
    h2 {
      line-height: 9vh;
      font-family: "Lobster", cursive;
      color: #08fdd8;
      font-weight: 400;
      margin-top: 0;
      position: relative;
      left: -10px;
      font-size: 5rem;
      margin: 0;
    }
    a {
        color: #08fdd8;
    }
    a:hover{
      color: #EE1D52;
      transition: 0.5s;
    }
    ul {
      margin-top: 0;
      p:first-of-type:before {
        content: '';
        font-family: 'La Belle Aurore',cursive;
        color: #515152;
        font-size: 18px;
        position: absolute;
        margin-top: -1.5rem;
        left: -2rem;
        }
      
      p:last-of-type:after {
        content: '';
        font-family: 'La Belle Aurore',cursive;
        color: #515152;
        font-size: 18px;
        position: absolute;
        left: -2rem;
        bottom: -2rem;
      }
    }
    li {
      margin-top: 10px;
    }
}
@keyframes background-pan {
  from {
    background-position: 0% center;
  }
  
  to {
    background-position: -200% center;
  }
}
.skills_container:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background:
    linear-gradient(rgba(255, 255, 255, 0.2) 1px, transparent 1px),
    linear-gradient(to right, rgba(255, 255, 255, 0.2) 1px, transparent 1px);
  background-size: 20px 20px, 20px 20px, 100% 20px;
  background-position: -10px -10px, -10px -10px, 0 0;
  opacity: 0.5;
}

.grid_container {
  height: 200px;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  background: #1B1A22;
  margin: 0;
  padding: 0;

  .grid_layout {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  background:
      linear-gradient(rgba(255, 255, 255, 0.2) 1px, transparent 1px),
      linear-gradient(to right, rgba(255, 255, 255, 0.2) 1px, transparent 1px),
      #1B1A22;
    background-size: 20px 20px, 20px 20px, 100% 20px;
    background-position: -10px -10px, -10px -10px, 0 0;
    background-attachment: absolute;
    opacity: 0.5;
  }
  .grid_fade_in {
    height: 100%;
    width: 100%;
    background: linear-gradient(#1B1A22,  transparent);
  }
  .grid_fade_out {
    height: 100%;
    width: 100%;
    background: linear-gradient(transparent,  #1B1A22);
  }
}

.skills_text p:first-of-type:before {
    content: '<p>';
    font-family: 'La Belle Aurore',cursive;
    color: #515152;
    font-size: 18px;
    position: absolute;
    margin-top: -1.5rem;
    left: -2rem;
    }

.skills_text p:last-of-type:after {
    content: '<p/>';
    font-family: 'La Belle Aurore',cursive;
    color: #515152;
    font-size: 18px;
    position: absolute;
    left: -2rem;
    bottom: -2rem;
}

.skills_details{
  padding-right: 5rem;
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.skills_explain{
  width: 100%;
  display: flex;
  flex-direction: row;
  height: auto;
  justify-content: space-between;
  margin-top: 5rem;
  position: relative;
  z-index: 1;
}

.skills_box{
  width: 48%;
  background-color: #2B2B37;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;
  header {
    font-family: 'Lobster', cursive;
    padding: 1rem;
    font-size: 2rem;
    line-height: 2rem;
  }
  p {
    font-family: 'Montserrat', sans-serif;
    position: relative;
    margin: 0 1rem;
    font-size: 1rem;
    line-height: 1.2rem;
    color: #ffffffb0;
    margin-bottom: 1rem;

  }
}

.skills-details{
  width: 45%;
}

.content_skills{
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.chart span {
  font-size: 18px;
  font-family: 'Lobster', cursive;
  margin-bottom: 7px;
  display: block;
}

.page-skills .text-zone+div {
  padding-right: 5rem;
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.chart {
  margin-bottom: 2rem;
  footer {
    background-color: #373737;
    height: 2px;
  }
  footer div.w90 {
    width: 0;
    background-color: #08fdd8;
    animation: w90 1s ease forwards;
    animation-delay: .6s;
    height: 2px;
  }
  footer div.w60 {
    width: 0;
    background: rgb(210, 108, 213);
    animation: w60 1s ease forwards;
    animation-delay: .7s;
    height: 2px;
  }
  footer div.w70 {
    width: 0;
    background: rgb(255, 34, 83); 
    animation: w70 1s ease forwards;
    animation-delay: .8s;
    height: 2px;
  }
  div.w50 {
    width: 0;
    background-color: #08fdd8;
    animation: w50 1s ease forwards;
    animation-delay: .9s;
    height: 2px;
  }
}

@keyframes w90 {
  from { width: 0%; }
  to { width: 90%; }
}

@keyframes w60 {
  from { width: 0%; }
  to { width: 60%; }
}

@keyframes w70 {
  from { width: 0%; }
  to { width: 75%; }
}

@keyframes w50 {
  from { width: 0%; }
  to { width: 68%; }
}

.p3::before{
    content:attr(type);
    display:block;
    margin-top: 1rem;
    color:#EE1D52;
    font-size: 1.5rem;
}

.animate__animated.animate__fadeIn {
  --animate-duration: 2s;
}

/* Phone settings */

@media (max-width: 768px) {
  .skills_container {
    flex-direction: column;
    height: auto;
  }
  .content_skills {
    flex-direction: column;
  }
  .skills_text {
    margin-top: 25px;
    width: 80%;
    h2 {
      font-size: 4rem;
    }
    p {
      font-size: 1rem;
      margin-top: 1rem;
      margin-bottom: 0;
    }
  }
  .skills-details {
    margin-top: 100px;
    width: 85%;
    .chart_box {
      margin-bottom: 100px;
    }
  }

  .skills_explain {
    flex-direction: column;
    margin: 0;
    align-items: center;

  }
  .skills_box {
    margin-bottom: 25px;
    width: 100%;
  }
  .svg_background {
    height: 100%;
  }
}