
/* Projects */

.projects {
    width: 60%;
    margin-top: 5%;
    margin-left: 15%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 1;
    position: fixed;
    button {
        background-color: #2a2a2a;
        width: 100%;
        height: 140px;
        border-radius: 10px;
        border-color: rgb(202, 202, 202);
        color: white;
        font-family: 'Montserrat', sans-serif;
        font-size: 3vh;
    }

    .koptekst {
        display: flex;
        flex-direction: row;
        h2 {
            font-family: "Lobster", cursive;
            font-weight: 400;
            font-size: 4rem;
            margin-top: 5px;
        }
        h1 {
            font-family: "Lobster", cursive;
            color: #08fdd8;
            font-size: 5rem;
            font-weight: 400;
            margin-top: 0;
        }
    }
}

.Modals {
    cursor: pointer;
    border: none;
    box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    transition: 0.3s;
    padding: 0px 0px 0px 0px;
    margin-bottom: 5%;
    img {
        margin-left: 0px;
        height: 140px;
        width: 187px;
        border-radius: 10px 0px 0px 10px;
    }
    
}

.Modals:hover{
    box-shadow: none;
    background-color: #313131;
    p {
        border-bottom: 1px solid white;
    }

}

.Modals:last-of-type{
    margin-bottom: 0%;
}

.Modal_Inner {
    display:flex;
    flex-direction: row;
}


.Modal_Inner2 {
    display:flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    .Modal_Lottie {
        width: 200px;
        height: 200px;
        object-fit: contain;
    }
}



.Modal_Info {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
        margin-top: 5px;
        margin-bottom: 10px;
        margin-left: 10%; 
        margin-right: 10%;
        border-bottom: 1px dashed white;
        transition: 0.3s;
        height: 40px;
    }
    .p2 {
        font-size: 1rem;
        color: rgba(255, 255, 255, 0.6901960784);
        text-align: left;
        margin: 10px;
        margin-left: 10%;
        margin-right: 10%;
    }
}

/* Phone settings */

// @media (max-width: 1024px) {
//     .background {
//         display: none;
//     }
//     .projects {
//         width: 90%;
//         margin-left: 5%;
//         button {
//             height: 17vh;
//         }
//         img {
//             display: none;
//         }
//         .Modal_Info{
//             align-items: center;
//             p {
//                 display: flex;
//                 justify-content: center;
//                 align-items: center;
//                 font-size: 1.2rem;
//                 margin-bottom: 0px;
//                 height: 30px;
//                 margin-top: 10px;
//                 margin-left: 5%;
//                 margin-right: 5%;
//             }
//             .p2 {
//                 font-size: 0.8rem;
//                 width: 95%;
//                 margin-left: 5%;
//                 margin-right: 5%;
//             }
//         }

//         h2 {
//             display: none;
//         }
//         .koptekst {
//             h1 {
//                 font-size: 3rem;
//             }
//         }

//     }


// }