@import "./components/about";
@import "./components/projects";
@import "./components/global";
@import "./components/home";
@import "./components/skills";
@import "./components/canvas";
@import "./components/footer";
@import "./abstracts/variables";
@import url('https://rsms.me/inter/inter.css');

/* Page that doesn't exist */
.notfound {
  font-size: xx-large;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}