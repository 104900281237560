/* Home page */

.home {
  position: relative;
  height: 100vh;
  margin: 0;
  overflow-x: hidden;
  display: -webkit-flex; /* Safari 8 */
  display: -ms-flexbox; /* IE 10 */
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background-color: transparent;
  }

.home_background {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  background-image: url('https://github.githubassets.com/images/modules/site/home-campaign/hero-bg.webp'); /* Fallback for browsers that don't support clip-path */
  background-size: cover;
  background-position: center center;
  border-style: none;
  position: absolute !important;
  top: 0 !important;
  z-index: 0;
}

  /* HomeText */

  .hometext {
    display: flex;
    align-items: flex-start;
    padding-left: 10%;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    cursor: default;
    h1 {
      color: #fff;
      margin: 0;
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      font-size: 6rem;
      line-height: 6.5rem;
      position: relative;
    }
    .HeaderHomeText{
      white-space: pre-wrap;
      h2 {
        width: 380px;
        font-size: 2rem;
        color: #EE1D52;
        font-family: 'Inter';
        margin: 0;
      }
      margin-bottom: 40px;
    }
    .FooterHomeText{
      display: flex;
      width: 100%;
      justify-content: flex-end;
      h3 {
        width: 150px;
        font-size: 1rem;
        font-family: 'Inter';
        float: right;
        text-align: right;
      }
    }
    .space{
      padding-right: 0.2em;
    }
    span:hover {
      color: #EE1D52;
      transition-duration: 0.1s;
    }
  }

  .AnimatedM{
    max-width: 40%;
  }
  
  .typing-animation {
    display: inline-block; /* Add display property to make the element inline */
    position: relative; /* Add relative positioning */
    overflow-x: hidden;
    animation: typing 1s steps(40, end);
  }
  
  .typing-animation::after {
    content: "_";
    position: absolute; /* Change back to absolute positioning */
    margin-left: 2px;
    animation: blink 1s infinite;
  }
  
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  
  @keyframes blink {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  /* Phone settings */

@media (max-width: 768px) {
  .home {
    margin-bottom: 0vh;
    .hometext {
        margin-left: 0;
        h1 {
          font-size: 10vh;
        }
      }
  }
}

