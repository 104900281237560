#gradient-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
  z-index: -1;
  --gradient-color-1: #7a153c;
  --gradient-color-2: #131c21;
  --gradient-color-3: #151525;
  --gradient-color-4: #1d2e3c;
}